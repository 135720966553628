import { useNavigate } from "react-router";
import { useCookies } from "react-cookie";
import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import logo from '../logo.png';
import SOSInput from '../components/sosinput';
import SOSButton from '../components/sosbutton';
import SOSMessage from '../components/sosmessage';
import SOSLinkButton from '../components/soslinkbutton';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';

function App() {
  const navigate = useNavigate();
  const [loginCookie, setLoginCookie, removeLoginCookie] = useCookies(['login_token']);
  const [loginToken, setLoginToken] = useState('');
  const [msgNoInput, setMsgNoInput] = useState(false);
  const [msgNoLogin, setMsgNoLogin] = useState(false);
  const [msgCaptchaCheck, setMsgCaptchaCheck] = useState(false);
  const [captchaVerified, setCaptchaVerified] = useState(false);

  const site_key = '6LeHFRYgAAAAALIJSIw5ht9rXIrhxARor6wRmYum';

  //go to overview page if logged in
  useEffect(() => {
    if (loginCookie.login_token) {
      navigate('../manage/overview');
    }
  });

  useEffect(() => {
    if (msgNoInput) {
      setTimeout(() => {
        setMsgNoInput(false);
      }, 5000);
    }
  }, [msgNoInput]);

  useEffect(() => {
    if (msgNoLogin) {
      setTimeout(() => {
        setMsgNoLogin(false);
      }, 5000);
    }
  }, [msgNoLogin]);

  useEffect(() => {
    if (msgCaptchaCheck) {
      setTimeout(() => {
        setMsgCaptchaCheck(false);
      }, 5000);
    }
  }, [msgCaptchaCheck]);

  const navigateHome = () => {
    navigate('../');
  };

  const handleVerify = () => {
    setCaptchaVerified(true);
  }

  //perform login request
  const login = event => {
    event.preventDefault();
    event.target.disabled = true;

    if (loginToken != '' && captchaVerified) {
      const json = {
        req_id: 'login',
        token: loginToken,
      };

      const header = { 'Accept': 'application/json', 'Content-Type': 'application/json' };

      axios.post(window.location.origin + '/db/sos-db-request.php/', json, { header }) //code to ./
      .then(response => {
        setLoginCookie('login_token', ((response.data)[0][0]).toString(), { path: '/', maxAge: 3600 }); //cookie expires after 1h
        event.target.disabled = false;
      })
      .catch( (error) => {
        setMsgNoLogin(true);
        event.target.disabled = false;
      });
    } else if (loginToken == '') {
      setMsgNoInput(true);
      event.target.disabled = false;
    } else if (!captchaVerified) {
      setMsgCaptchaCheck(true);
      event.target.disabled = false;
    } else {
      setMsgNoInput(true);
      event.target.disabled = false;
    }
  }

  return (
    <div className="SOS grey">
      <header className="SOS-header">
        <img className="SOS-header-logo" src={logo}  alt="LHG-Logo"/>
        <h1 className="SOS-header-title">SOS Login</h1>
      </header>
      <div className="SOS-content">
        <p>Hier könnt ihr Vertrauenspersonen für Veranstaltungen für unsere SOS-Anlaufstelle hinzufügen. Loggt euch dazu mit dem zur Verfügung gestellten Authentication-Key ein.</p>
        <p>Falls ihr noch keinen Key habt, wendet euch an <a href="mailto:it@bundes-lhg.de" title="Mail an it@bundes-lhg.de">it@bundes-lhg.de.</a></p>
        <GoogleReCaptchaProvider reCaptchaKey={site_key}>
          <form onSubmit={login}>
            <SOSInput title="Authentication-Key" type="password" onChg={(event) => setLoginToken(event.target.value)}  />
            <SOSButton title="Einloggen" click={login}/>
          </form>
          <GoogleReCaptcha onVerify={handleVerify} />
        </GoogleReCaptchaProvider>

        <SOSMessage title="Fehler" message="Bitte gib ein Passwort ein." show={msgNoInput} addClass="red" click={() => setMsgNoInput(false)}/>
        <SOSMessage title="Fehler" message="Der Login ist fehlgeschlagen, bitte überprüfe deine Eingabe." show={msgNoLogin} addClass="red" click={() => setMsgNoLogin(false)}/>
        <SOSMessage title="reCaptcha" message="Wir überprüfen noch, ob du kein Roboter bist. Bitte probiere es in einigen Sekunden erneut" show={msgCaptchaCheck} click={() => setMsgCaptchaCheck(false)}/>
      </div>
      <footer className="SOS-footer">
        <p>Damit diese Seite funktioniert setzen wir Cookies ein. Wenn du dich einloggst akzeptierst du die Verwendung dieser.</p>
        <SOSLinkButton title="Zur Startseite" click={navigateHome}/>
      </footer>
    </div>
  );
}

export default App;
