import './components.css';
import { useState, useEffect } from "react";
import axios from "axios";
import SOSSelect from './sosselect.js';
import SOSLargeImgButton from './soslargeimgbutton.js';
import engel from '../icons/engel.png';


export default function SOSTrustPersonDropdown({addClass ='', title, items = []}) {
  const [selectedEvent, setSelectedEvent] = useState([]);
  const [trustPersons, setTrustPersons] = useState([]);
  const [trustPersonsCode, setTrustPersonsCode] = useState();
  const [eventNames, setEventNames] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    var arr = [];
    items.forEach((el, i) => {
      arr[i] = el[0];
    });
    setEventNames(arr);
  }, [items])

  //call number
  const callNumber = number => {
    window.location.assign("tel:" + number.replace(/\s/g,''));
  }

  //set selected event
  const eventSelect = (e) => {
    setSelectedEvent(items[e.target.selectedIndex - 1]);
  }

  //get trust persons from server when event is selected
  useEffect(() => {
    setTrustPersons([]);

    if (selectedEvent.length > 0 && selectedEvent[2].length > 0) {
      setLoading(true);

      axios.get(window.location.origin + '/db/sos-db-request.php?req_id=get_trust_persons&trust_persons=' + selectedEvent[2]) //code to ./
      .then(response => {
        setTrustPersons(response.data);
        setLoading(false);
      })
      .catch( (error) => {
        setLoading(false);
        console.log(error);
      });
    }
  }, [selectedEvent])

  //set trust persons when result is received
  useEffect(() => {
    if (trustPersons.length > 0) {
      setTrustPersonsCode(trustPersons.map((person, i) => <SOSLargeImgButton key={i} title={person[1]} subtitle={person[2]} click={() => callNumber(person[2])} imgSrc={engel} url={person[3] !== '' ? window.location.origin + '/' + person[3] : ''}/>));
    } else if (selectedEvent.length !== 0) {
      setTrustPersonsCode(<p>Leider wurden keine Vertrauenspersonen gefunden. Kontaktiere doch am Besten das Ombudsmitglied.</p>);
    }
  }, [trustPersons])

  return (
    <div className="SOS-search-input SOS-trust-person-search">
      <SOSSelect title={title} values={eventNames} change={eventSelect}/>

      <div className={loading ? 'loading-round SOS-trust-person-search-event' : 'SOS-trust-person-search-event'} >
        <div className="SOS-content-grid">
          {trustPersonsCode}
        </div>
      </div>
    </div>
  );
}
