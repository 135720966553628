import { useNavigate } from "react-router";
import logo from '../../logo.png';
import { useCookies } from "react-cookie";
import SOSImg from '../../components/sosimg';
import SOSLinkButton from '../../components/soslinkbutton';
import fragezeichen from '../../icons/fragezeichen.png';

export default function App() {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['bgColor']);

  const navigateBack = () => {
    navigate(-1);
  }

  function handleDarkMode() {
    if (cookies.bgColor !== 'dark') {
      setCookie('bgColor', 'dark', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    } else {
      setCookie('bgColor', 'red', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    }
  }

  const navigateVertrauenspersonen = () => {
    navigate('../../kontakt/vertrauenspersonen');
  };
  const navigateOmbud = () => {
    navigate('../../kontakt/ombudsperson');
  };
  const navigateBundesvorstand = () => {
    window.location.assign('https://liberale-hochschulgruppen.de/vorstand/');
  };
  const navigateLandesvorstand = () => {
    navigate('../../kontakt/landesvorstand');
  };
  const navigateSchiedsgericht = () => {
    navigate('../../kontakt/bundesschiedsgericht');
  };
  const navigateOrganisatoren = () => {
    navigate('/kontakt/organisatoren')
  }

  return (
    <div className={'SOS ' + cookies.bgColor}>
      <header className="SOS-header">
        <a href="/" title="Home"><img className="SOS-header-logo" src={logo} alt="LHG-Logo" /></a>
        <h1 className="SOS-header-title"><button className="SOS-back-button" onClick={navigateBack}>&lt;</button>An wen soll ich<br/>mich wenden?</h1>
        <SOSLinkButton addClass="SOS-header-privacy-button no-underline" title="🔅" click={handleDarkMode}/>
      </header>
      <div className="SOS-content">
        <SOSImg imgSrc={fragezeichen} />
        <p>Keine Sorge, wir finden die richtige Ansprechperson für dich.</p>
        <h2>Vertrauenspersonen</h2>
        <p>Wenn du gerade auf einer Veranstaltungen des Bundes- oder eines Landesverbands bist, gibt es oft <b><SOSLinkButton addClass="no-margin" title="Vertrauenspersonen" click={navigateVertrauenspersonen}/></b>. Diese unterstützen dich jederzeit persönlich vor Ort.</p>
        <h2>Organisatoren</h2>
        <p>Wenn du auf einer Veranstaltungen des Bundes- oder eines Landesverbands organisatorische Hilfe benötigts oder dich verirrt hast, kontaktiere einfach die <b><SOSLinkButton addClass="no-margin" title="Organisatoren" click={navigateOrganisatoren}/></b>.</p>
        <h2>Bundesombudsperson</h2>
        <p>Wann immer du dir Sorgen machst, persönlich betroffen bist oder es um das Verhalten von Mitgliedern geht, wende dich am Besten vertrauensvoll und gerne auch anonym an unsere <b><SOSLinkButton addClass="no-margin" title="Bundesombudsperson" click={navigateOmbud}/></b>.</p>
        <h2>Bundeschiedsgericht</h2>
        <p>Solltest du Hilfe benötigen weil ein Mitglied oder ein Gremium (bspw. ein Vorstand) gegen die Bundessatzung verstoßen, kannst du dich jederzeit direkt an das <b><SOSLinkButton addClass="no-margin" title="Bundesschiedsgericht" click={navigateSchiedsgericht}/></b> als vermittelnde Instanz wenden.</p>
        <h2>Landesvorstand</h2>
        <p>In deinem Landesverband unterstützt dich dein <b><SOSLinkButton addClass="no-margin" title="Landesvorstand" click={navigateLandesvorstand}/></b> ebenfalls gerne bei Sorgen und Problemen. Du weißt gar nicht, wer in deinem LaVo für dich da ist? Kein Problem - wir helfen dir gerne, den richtigen Kontakt zu finden.</p>
        <h2>Bundesvorstand</h2>
        <p>Möchtest du lieber mit einem Mitglied des <b><SOSLinkButton addClass="no-margin" title="Bundesvorstand" click={navigateBundesvorstand}/></b> sprechen? Kein Problem, auch diese sind immer gerne für dich erreichbar.</p>
      </div>
      <footer className="SOS-footer">

      </footer>
    </div>
  );
}
