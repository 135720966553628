import SOSButton from '../components/sosbutton';
import SOSLinkButton from '../components/soslinkbutton';
import SOSImgButton from '../components/sosimgbutton';
import { useNavigate } from "react-router";
import logo from '../logo.png';
import { useCookies } from "react-cookie";
import vielfalt from '../icons/vielfalt.png';
import gelbesherz from '../icons/gelbes-herz.png';
import buvo from '../icons/buvo.png';
import lavo from '../icons/lavo.png';
import schiedsgericht from '../icons/schiedsgericht.png';
import sos from '../icons/sos.png';
import anzug from '../icons/anzug.png';

export default function App() {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['bgColor']);

  const navigateBack = () => {
    navigate(-1);
  }

  const navigateAwsimw = () => {
    navigate('./an-wen-soll-ich-mich-wenden');
  };
  const navigateVertrauenspersonen = () => {
    navigate('./vertrauenspersonen');
  };
  const navigateOmbud = () => {
    navigate('./ombudsperson');
  };
  const navigateBundesvorstand = () => {
    window.location.assign('https://liberale-hochschulgruppen.de/vorstand/');
  };
  const navigateLandesvorstand = () => {
    navigate('./landesvorstand');
  };
  const navigateSchiedsgericht = () => {
    navigate('./bundesschiedsgericht');
  };
  const navigateEmergencyCall = () => {
    navigate('../emergency-call');
  };
  const navigateOrganisatoren = () => {
    navigate('/kontakt/organisatoren')
  }

  function handleDarkMode() {
    if (cookies.bgColor !== 'dark') {
      setCookie('bgColor', 'dark', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    } else {
      setCookie('bgColor', 'red', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    }
  }

  return (
    <div className={'SOS ' + cookies.bgColor}>
      <header className="SOS-header">
        <a href="/" title="Home"><img className="SOS-header-logo" src={logo} alt="LHG-Logo" /></a>
        <h1 className="SOS-header-title"><button className="SOS-back-button" onClick={navigateBack}>&lt;</button>Kontakt</h1>
        <SOSLinkButton addClass="SOS-header-privacy-button no-underline" title="🔅" click={handleDarkMode}/>
      </header>
      <div className="SOS-content">
        <p><b>Wähle bitte in Notsituationen unverzüglich den Notruf!</b></p>
        <p>Du kannst dich jederzeit vertraulich an uns wenden. Unsere Ombudsperson ist immer für dich da. Wenn du gerade bei einer unserer Veranstaltungen bist, dann wende dich doch am Besten an die Vertrauenspersonen vor Ort. Du kannst dich aber natürlich auch an ein Mitglied des Bundesvorstandes, der Landesvorstände oder das Bundeschiedsgericht wenden.</p>
        <div className="SOS-content-grid">
          <SOSImgButton title="Notruf" imgSrc={sos} click={navigateEmergencyCall}/>
          <SOSImgButton title={<span>Vertrauens<wbr/>&shy;personen</span>} imgSrc={vielfalt} click={navigateVertrauenspersonen}/>
          <SOSImgButton title="Organisatoren" imgSrc={anzug} click={navigateOrganisatoren}/>
          <SOSImgButton title="Ombudsperson" imgSrc={gelbesherz} click={navigateOmbud}/>
          <SOSImgButton title="BuVo-Mitglied" imgSrc={buvo} click={navigateBundesvorstand}/>
          <SOSImgButton title="Landesvorstand" imgSrc={lavo} click={navigateLandesvorstand}/>
          <SOSImgButton title={<span>Bundesschieds<wbr/>&shy;gericht</span>} imgSrc={schiedsgericht} click={navigateSchiedsgericht}/>
        </div>
        <SOSButton title="An wen soll ich mich wenden?" click={navigateAwsimw}/>
      </div>
      <footer className="SOS-footer">

      </footer>
    </div>
  );
}
