import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import logo from '../../logo.png';
import { useCookies } from "react-cookie";
import axios from "axios";
import SOSImg from '../../components/sosimg';
import SOSInput from '../../components/sosinput';
import SOSTextarea from '../../components/sostextarea';
import SOSButton from '../../components/sosbutton';
import SOSMessage from '../../components/sosmessage';
import SOSLinkButton from '../../components/soslinkbutton';
import schloss from '../../icons/schloss.png';


export default function App() {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['bgColor']);
  const [ombudMail, setOmbudMail] = useState('');
  const [mailContent, setMailContent] = useState('');
  const [mailContact, setMailContact] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const [msgNoContent, setMsgNoContent] = useState(false);
  const [msgError, setMsgError] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState(false);

  const navigateBack = () => {
    navigate(-1);
  }

  useEffect(() => {
    //get ombud email address
    axios.get(window.location.origin + '/db/sos-db-request.php?req_id=get_setting&name=ombudsperson_mail') //code to ./
    .then(response => {
      setOmbudMail(response.data[0][0]);
    });
  }, []);

  useEffect(() => {
    if (msgNoContent || msgError || msgSuccess) {
      setTimeout(() => {
        setMsgNoContent(false);
        setMsgError(false);
        setMsgSuccess(false);
      }, 5000);
    }
  }, [msgNoContent, msgError, msgSuccess]);

  function handleDarkMode() {
    if (cookies.bgColor !== 'dark') {
      setCookie('bgColor', 'dark', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    } else {
      setCookie('bgColor', 'red', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    }
  }

  const handleSend = (event) => {
    event.preventDefault();
    setDisableButton(true);

    if (mailContent === '') {
      setMsgNoContent(true);
      setDisableButton(false);
      return;
    }

    var body = 'Eine Nachricht an dich wurde gesendet:<br><br>';
    body += mailContact === '' ? mailContent : mailContent + '<br><br>Der/Die Absender/in w&uuml;nscht, dass du ihn/sie unter folgender Kontaktm&ouml;glichkeit kontaktierst:<br>' + mailContact;

    const json = {
      req_id: 'send_mail',
      to: ombudMail,
      subject: 'SOS-APP | Eine anonyme Nachricht aus der SOS-App',
      message: body
    };

    const header = { 'Accept': 'application/json', 'Content-Type': 'application/json' };

    axios.post(window.location.origin + '/db/sos-mail.php', json, header) //code to ./
    .then(response => {
      setMsgSuccess(true);
      setDisableButton(false);
      setTimeout(() => {navigateBack()}, 5000);
    })
    .catch( (error) => {
      setMsgError(true);
      setDisableButton(false);
    });
  }


  return (
    <div className={'SOS ' + cookies.bgColor}>
      <header className="SOS-header">
        <a href="/" title="Home"><img className="SOS-header-logo" src={logo} alt="LHG-Logo" /></a>
        <h1 className="SOS-header-title"><button className="SOS-back-button" onClick={navigateBack}>&lt;</button>Anonymer Kontakt</h1>
        <SOSLinkButton addClass="SOS-header-privacy-button no-underline" title="🔅" click={handleDarkMode}/>
      </header>
      <div className="SOS-content">
        <SOSImg imgSrc={schloss} />
        <p>Hier kannst du dich <b>anonym</b> an unsere <b>Ombudsperson</b> wenden.</p>
        <p>Bedenke bitte, dass diese keine Möglichkeit haben wird, dich direkt zu kontaktieren. Falls du das möchtest, vergiss nicht, eine Kontaktmöglichkeit anzugeben.</p>
        <form>
          <SOSTextarea title="Deine Nachricht" onChg={(event) => setMailContent(event.target.value)}/>
          <SOSInput title="Kontaktmöglichkeit (freiwillig)" onChg={(event) => setMailContact(event.target.value)}/>
          <SOSButton title="Senden" click={(event) => handleSend(event)} disabled={disableButton}/>
        </form>
      </div>
      <SOSMessage title="Fehler" message="Bitte gib eine Nachricht ein." show={msgNoContent} addClass="red" click={() => setMsgNoContent(false)}/>
      <SOSMessage title="Fehler" message="Deine Nachricht konnte nicht gesendet werden. Bitte versuche es später erneut oder wähle eine andere Kontaktmöglichkeit." show={msgError} addClass="red" click={() => setMsgError(false)}/>
      <SOSMessage title="Erfolg" message="Deine Nachricht wurde gesendet." show={msgSuccess} addClass="green" click={() => setMsgSuccess(false)}/>
      <footer className="SOS-footer">
      </footer>
    </div>
  );
}
