import SOSImgButton from '../../components/sosimgbutton';
import SOSLinkButton from '../../components/soslinkbutton';
import { useNavigate } from "react-router";
import logo from '../../logo.png';
import { useCookies } from "react-cookie";
import helm from '../../icons/helm.png';
import dokument from '../../icons/dokument.png';
import sprechen from '../../icons/sprechen.png';

export default function App() {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['bgColor']);

  const navigateBack = () => {
    navigate('../assistenz?restore=true');
  }
  const navigateKontakt = () => {
    navigate('/kontakt')
  }
  const navigateCoC = () => {
    navigate('../hilfsangebote/code-of-conduct');
  };
  const navigateSatzung = () => {
    window.location.assign('https://liberale-hochschulgruppen.de/wp-content/uploads/2022/02/LHG_Satzung_Februar22.pdf');
  };

  function handleDarkMode() {
    if (cookies.bgColor !== 'dark') {
      setCookie('bgColor', 'dark', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    } else {
      setCookie('bgColor', 'red', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    }
  }

  return (
    <div className={'SOS ' + cookies.bgColor}>
      <header className="SOS-header">
        <a href="/" title="Home"><img className="SOS-header-logo" src={logo} alt="LHG-Logo" /></a>
        <h1 className="SOS-header-title"><button className="SOS-back-button" onClick={navigateBack}>&lt;</button>Assistenz</h1>
        <SOSLinkButton addClass="SOS-header-privacy-button no-underline" title="🔅" click={handleDarkMode}/>
      </header>
      <div className="SOS-content">
        <h1>Hilfe bekommen</h1>
        <p>Erstmal tief durchatmen. Vielleicht hilft dir erstmal ein Blick in unseren Code of Conduct oder die Satzung.</p>
        <p>Du kannst dich natürlich auch jederzeit direkt an unsere Vertrauenspersonen oder das Bundesombudsmitglied wenden. Diese helfen dir vertraulich weiter. Auch die Mitglieder des Bundesvorstands sowie deines Landesvorstands haben immer ein offenes Ohr für dich.</p>
        <div className="SOS-content-grid">
          <SOSImgButton title="Code of Conduct" imgSrc={helm} click={navigateCoC}/>
          <SOSImgButton title="Satzung" imgSrc={dokument} click={navigateSatzung}/>
          <SOSImgButton title={<span>Kontakt<wbr/>&shy;möglichkeiten</span>} imgSrc={sprechen} click={navigateKontakt}/>
        </div>
      </div>
      <footer className="SOS-footer">
      </footer>
    </div>
  );
}
