import './Style.css';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import React from 'react';
import { CookiesProvider } from "react-cookie";
//pages frontend
import Home from './frontend/Home';
import Kontakt from './frontend/Kontakt';
import AnWenSollIchMichWenden from './frontend/Kontakt/An-wen-soll-ich-mich-wenden';
import Vertrauenspersonen from './frontend/Kontakt/Vertrauenspersonen';
import Ombudsperson from './frontend/Kontakt/Ombudsperson';
import AnonymerKontakt from './frontend/Kontakt/Anonymer-Kontakt';
import Bundesvorstand from './frontend/Kontakt/Bundesvorstand';
import Landesvorstand from './frontend/Kontakt/Landesvorstand';
import Bundesschiedsgericht from './frontend/Kontakt/Bundesschiedsgericht';
import Organisatoren from './frontend/Kontakt/Organisatoren';
import Assistenz from './frontend/Assistenz';
import AssistenzNotfall from './frontend/Assistenz/Assistenz-notfall';
import AssistenzSprechen from './frontend/Assistenz/Assistenz-sprechen';
import AssistenzBelaestigungEvent from './frontend/Assistenz/Assistenz-belaestigung-event';
import AssistenzBelaestigungNoEvent from './frontend/Assistenz/Assistenz-belaestigung-noevent';
import AssistenzKonfliktEventSelf from './frontend/Assistenz/Assistenz-konflikt-event-self';
import AssistenzKonfliktEventOther from './frontend/Assistenz/Assistenz-konflikt-event-other';
import AssistenzKonfliktNoEventSelf from './frontend/Assistenz/Assistenz-konflikt-noevent-self';
import AssistenzKonfliktNoEventOther from './frontend/Assistenz/Assistenz-konflikt-noevent-other';
import AssistenzAngriffEvent from './frontend/Assistenz/Assistenz-angriff-event';
import AssistenzAngriffNoEvent from './frontend/Assistenz/Assistenz-angriff-noevent';
import AssistenzFragenVeranstaltung from './frontend/Assistenz/Assistenz-fragen-veranstaltung';
import AssistenzSonstigeEvent from './frontend/Assistenz/Assistenz-sonstige-event';
import AssistenzSonstigeNoEvent from './frontend/Assistenz/Assistenz-sonstige-noevent';
import Hilfsangebote from './frontend/Hilfsangebote';
import CodeOfConduct from './frontend/Hilfsangebote/Code-of-conduct';
import Impressum from './frontend/About';
import EmergencyCall from './frontend/Emergency-Call';
//pages backend
import Login from './backend/Login';
import Overview from './backend/Overview';
import EditEvent from './backend/Edit-event';
import AddEvent from './backend/Add-event';

function SOS() {
  return (
    <CookiesProvider>
      <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/kontakt" element={<Kontakt />} />
            <Route path="/kontakt/an-wen-soll-ich-mich-wenden" element={<AnWenSollIchMichWenden />} />
            <Route path="/kontakt/vertrauenspersonen" element={<Vertrauenspersonen />} />
            <Route path="/kontakt/ombudsperson" element={<Ombudsperson />} />
            <Route path="/kontakt/anonymer-kontakt" element={<AnonymerKontakt />} />
            <Route path="/kontakt/bundesvorstand" element={<Bundesvorstand />} />
            <Route path="/kontakt/landesvorstand" element={<Landesvorstand />} />
            <Route path="/kontakt/bundesschiedsgericht" element={<Bundesschiedsgericht />} />
            <Route path="/kontakt/organisatoren" element={<Organisatoren />} />
            <Route path="/hilfsangebote" element={<Hilfsangebote />} />
            <Route path="/hilfsangebote/code-of-conduct" element={<CodeOfConduct />} />
            <Route path="/assistenz" element={<Assistenz />} />
            <Route path="/assistenz/assistenz-notfall" element={<AssistenzNotfall />} />
            <Route path="/assistenz/assistenz-sprechen" element={<AssistenzSprechen />} />
            <Route path="/assistenz/assistenz-belaestigung-event" element={<AssistenzBelaestigungEvent />} />
            <Route path="/assistenz/assistenz-belaestigung-noevent" element={<AssistenzBelaestigungNoEvent />} />
            <Route path="/assistenz/assistenz-konflikt-event-self" element={<AssistenzKonfliktEventSelf />} />
            <Route path="/assistenz/assistenz-konflikt-event-other" element={<AssistenzKonfliktEventOther />} />
            <Route path="/assistenz/assistenz-konflikt-noevent-self" element={<AssistenzKonfliktNoEventSelf />} />
            <Route path="/assistenz/assistenz-konflikt-noevent-other" element={<AssistenzKonfliktNoEventOther />} />
            <Route path="/assistenz/assistenz-angriff-event" element={<AssistenzAngriffEvent />} />
            <Route path="/assistenz/assistenz-angriff-noevent" element={<AssistenzAngriffNoEvent />} />
            <Route path="/assistenz/assistenz-fragen-veranstaltung" element={<AssistenzFragenVeranstaltung />} />
            <Route path="/assistenz/assistenz-sonstige-event" element={<AssistenzSonstigeEvent />} />
            <Route path="/assistenz/assistenz-sonstige-noevent" element={<AssistenzSonstigeNoEvent />} />
            <Route path="/emergency-call" element={<EmergencyCall />} />
            <Route path="/login" element={<Login />} />
            <Route path="/manage/overview" element={<Overview />} />
            <Route path="/manage/edit-event" element={<EditEvent />} />
            <Route path="/manage/add-event" element={<AddEvent />} />
            <Route path="/impressum" element={<Impressum />} />
          </Routes>
      </BrowserRouter>
    </CookiesProvider>
  );
}

export default SOS;
