import { useNavigate } from "react-router";
import logo from '../../logo.png';
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import axios from "axios";
import SOSImg from '../../components/sosimg';
import SOSOrgaPersonDropdown from '../../components/sosorgapersondropdown';
import SOSImgButton from '../../components/sosimgbutton';
import SOSLinkButton from '../../components/soslinkbutton';
import anzug from '../../icons/anzug.png';
import gelbesherz from '../../icons/gelbes-herz.png';


export default function App() {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['bgColor']);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    //get events
    axios.get(window.location.origin + '/db/sos-db-request.php?req_id=get_upcoming_events') //code to ../
    .then(response => setEvents(response.data))
    .catch( (error) => {
      console.log(error);
    });
  },[]);

  const navigateBack = () => {
    navigate(-1);
  }

  const navigateOmbud = () => {
    navigate('../kontakt/ombudsperson');
  }

  function handleDarkMode() {
    if (cookies.bgColor !== 'dark') {
      setCookie('bgColor', 'dark', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    } else {
      setCookie('bgColor', 'red', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    }
  }

  return (
    <div className={'SOS ' + cookies.bgColor}>
      <header className="SOS-header">
        <a href="/" title="Home"><img className="SOS-header-logo" src={logo} alt="LHG-Logo" /></a>
        <h1 className="SOS-header-title"><button className="SOS-back-button" onClick={navigateBack}>&lt;</button>Organisatoren</h1>
        <SOSLinkButton addClass="SOS-header-privacy-button no-underline" title="🔅" click={handleDarkMode}/>
      </header>
      <div className="SOS-content">
        <SOSImg imgSrc={anzug} />
        <p>Bist du gerade auf einer LHG-Veranstaltung eines Landes- oder des Bundesverbands?</p>
        <p>Solltest du dort organisatorische Hilfe benötigen oder dich bspw. verirrt haben, gibt es oft <b>Organisatoren</b>, welche dir während der gesamten Veranstaltung zur Verfügung stehen und vor Ort für dich da sind.</p>
        <p>Falls du hier keine Organisatoren findest, erkundige dich am Besten bei Teilnehmern vor Ort oder wende dich an unser <b>Bundesombudsmitglied</b>.</p>
        <h3>Du kannst hier nach Veranstaltungen suchen, um die Organisatoren zu erfahren.</h3>
        <SOSOrgaPersonDropdown title="Veranstaltungssuche..." items={events}/>
        <div className="SOS-content-grid">
          <SOSImgButton title="Ombudsperson" imgSrc={gelbesherz} click={navigateOmbud}/>
        </div>
      </div>
      <footer className="SOS-footer">

      </footer>
    </div>
  );
}
