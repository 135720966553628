import SOSImgButton from '../../components/sosimgbutton';
import SOSLinkButton from '../../components/soslinkbutton';
import { useNavigate } from "react-router";
import logo from '../../logo.png';
import { useCookies } from "react-cookie";
import gelbesherz from '../../icons/gelbes-herz.png';
import vielfalt from '../../icons/vielfalt.png';

export default function App() {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['bgColor']);

  const navigateBack = () => {
    navigate('../assistenz?restore=true');
  }
  const navigateOmbudsmitglied = () => {
    navigate('/kontakt/ombudsperson')
  }
  const navigateVertrauenspersonen = () => {
    navigate('/kontakt/vertrauenspersonen')
  }

  function handleDarkMode() {
    if (cookies.bgColor !== 'dark') {
      setCookie('bgColor', 'dark', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    } else {
      setCookie('bgColor', 'red', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    }
  }

  return (
    <div className={'SOS ' + cookies.bgColor}>
      <header className="SOS-header">
        <a href="/" title="Home"><img className="SOS-header-logo" src={logo} alt="LHG-Logo" /></a>
        <h1 className="SOS-header-title"><button className="SOS-back-button" onClick={navigateBack}>&lt;</button>Assistenz</h1>
        <SOSLinkButton addClass="SOS-header-privacy-button no-underline" title="🔅" click={handleDarkMode}/>
      </header>
      <div className="SOS-content">
        <h1>Du beobachtest einen Konflikt?</h1>
        <p>Tief durchatmen. Von außen ist es oft schwer einzuschätzen, warum Personen sich streiten. Dennoch kannst du vielleicht versuchen, in Ruhe mit den Betroffenen zu reden und vielleicht sogar zu vermitteln.</p>
        <p>Falls du nicht weißt, wie du helfen kannst oder dich damit unwohl fühlst, kannst dich natürlich auch jederzeit an die Vertrauenspersonen vor Ort oder auch an das Bundesombudsmitglied wenden - auch anonym. Diese helfen vertraulich weiter und werden sich bemühen, zwischen den Betroffenen zu vermitteln.</p>
        <div className="SOS-content-grid">
          <SOSImgButton title="Ombudsmitglied" imgSrc={gelbesherz} click={navigateOmbudsmitglied}/>
          <SOSImgButton title={<span>Vertrauens<wbr/>&shy;personen</span>} imgSrc={vielfalt} click={navigateVertrauenspersonen}/>
        </div>
      </div>
      <footer className="SOS-footer">
      </footer>
    </div>
  );
}
