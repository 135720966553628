import './components.css';

export default function SOSSelect({addClass ='', title, values = [], change}) {
    const options = [];

    values.forEach((v) => {
      options.push(<option key={v}>{v}</option>);
    });

    return (
      <select className={"SOS-select " + addClass} onChange={change}>
        <option disabled selected hidden>{title}</option>
        {options}
      </select>
    );
}
