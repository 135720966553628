import './components.css';
import loadinggif from '../icons/loading.gif';


export default function SOSImg({imgSrc, addClass = '', click, loading, url = ''}) {
  var style;

  if (loading) {
    style = {
      img: {
        backgroundImage: `url(${loadinggif})`
      }
    }
  } else if (url !== '') {
    style = {
      img: {
        backgroundImage: 'url(' + url + ')',
        backgroundSize: 'cover',
      }
    }
  } else {
    style = {
      img: {
        backgroundImage: `url(${imgSrc})`
      }
    }
  }


  return (
      <div className={loading ? "SOS-image loading " + addClass : "SOS-image " + addClass} style={style.img} onClick={click}>&nbsp;</div>
  );
}
