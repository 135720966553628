import SOSButton from '../components/sosbutton';
import SOSLinkButton from '../components/soslinkbutton';
import SOSImgButton from '../components/sosimgbutton';
import SOSImg from '../components/sosimg';
import SOSTimer from '../components/sostimer';
import React from "react";
import { useNavigate } from "react-router";
import logo from '../logo.png';
import { useCookies } from "react-cookie";
import sos from '../icons/sos.png';

export default function App() {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['bgColor']);

  const navigateBack = () => {
    navigate(-1);
  }

  const makeSOSCall = () => {
    window.location.assign("tel:112");
  }

  function handleDarkMode() {
    if (cookies.bgColor !== 'dark') {
      setCookie('bgColor', 'dark', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    } else {
      setCookie('bgColor', 'red', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    }
  }

  return (
    <div className={'SOS ' + cookies.bgColor}>
      <header className="SOS-header">
        <a href="/" title="Home"><img className="SOS-header-logo" src={logo} alt="LHG-Logo" /></a>
        <h1 className="SOS-header-title"><button className="SOS-back-button" onClick={navigateBack}>&lt;</button>Notruf</h1>
        <SOSLinkButton addClass="SOS-header-privacy-button no-underline" title="🔅" click={handleDarkMode}/>
      </header>
      <div className="SOS-content">
        <SOSImg imgSrc={sos} />
        <h1>DER NOTRUF WIRD AUTOMATISCH GEWÄHLT IN</h1>
        <SOSTimer sec='10' action={makeSOSCall}/>
        <SOSButton title="ABBRECHEN" click={navigateBack}/>
      </div>
      <footer className="SOS-footer">

      </footer>
    </div>
  );
}
