import './components.css';

export default function SOSImgButton({addClass = '', title, imgSrc, click}) {
  const style = {
    img: {
      backgroundImage: `url(${imgSrc})`
    }
  }

  return (
    <button className={"SOS-img-button " + addClass} onClick={click} title={title}>
      <div className="SOS-img-button-icon" style={style.img}>&nbsp;</div>
      <p>{title}</p>
    </button>
  );
}
