import SOSImgButton from '../../components/sosimgbutton';
import SOSLinkButton from '../../components/soslinkbutton';
import { useNavigate } from "react-router";
import logo from '../../logo.png';
import { useCookies } from "react-cookie";
import gelbesherz from '../../icons/gelbes-herz.png';
import vielfalt from '../../icons/vielfalt.png';
import buvo from '../../icons/buvo.png';

export default function App() {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['bgColor']);

  const navigateBack = () => {
    navigate('../assistenz?restore=true');
  }
  const navigateOmbudsmitglied = () => {
    navigate('/kontakt/ombudsperson')
  }
  const navigateVertrauenspersonen = () => {
    navigate('/kontakt/vertrauenspersonen')
  }
  const navigateBundesvorstand = () => {
    window.location.assign('https://liberale-hochschulgruppen.de/vorstand/');
  };

  function handleDarkMode() {
    if (cookies.bgColor !== 'dark') {
      setCookie('bgColor', 'dark', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    } else {
      setCookie('bgColor', 'red', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    }
  }

  return (
    <div className={'SOS ' + cookies.bgColor}>
      <header className="SOS-header">
        <a href="/" title="Home"><img className="SOS-header-logo" src={logo} alt="LHG-Logo" /></a>
        <h1 className="SOS-header-title"><button className="SOS-back-button" onClick={navigateBack}>&lt;</button>Assistenz</h1>
        <SOSLinkButton addClass="SOS-header-privacy-button no-underline" title="🔅" click={handleDarkMode}/>
      </header>
      <div className="SOS-content">
        <h1>Hilfe bekommen</h1>
        <p>Erstmal tief durchatmen. Du kannst dich jederzeit direkt an unsere Vertrauenspersonen oder an das Bundesombudsmitglied wenden. Diese helfen dir vertraulich weiter. </p>
        <p>Auch die Mitglieder des Bundesvorstands haben immer ein offenes Ohr für dich.</p>
        <div className="SOS-content-grid">
          <SOSImgButton title="Ombudsmitglied" imgSrc={gelbesherz} click={navigateOmbudsmitglied}/>
          <SOSImgButton title={<span>Vertrauens<wbr/>&shy;personen</span>} imgSrc={vielfalt} click={navigateVertrauenspersonen}/>
          <SOSImgButton title="BuVo-Mitglied" imgSrc={buvo} click={navigateBundesvorstand}/>
        </div>
      </div>
      <footer className="SOS-footer">
      </footer>
    </div>
  );
}
