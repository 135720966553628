import { useNavigate } from "react-router";
import { useState, useEffect } from "react";
import logo from '../../logo.png';
import { useCookies } from "react-cookie";
import axios from "axios";
import parse from "html-react-parser";
import SOSImg from '../../components/sosimg';
import SOSImgButton from '../../components/sosimgbutton';
import SOSLinkButton from '../../components/soslinkbutton';
import gelbesherz from '../../icons/gelbes-herz.png';
import telefon from '../../icons/telefon.png';
import email from '../../icons/email.png';
import schloss from '../../icons/schloss.png';


export default function App() {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['bgColor']);
  const [ombudImage, setOmbudImage] = useState('');
  const [ombudText, setOmbudText] = useState('');
  const [ombudMail, setOmbudMail] = useState('');
  const [ombudPhone, setOmbudPhone] = useState('');


  useEffect(() => {
    //get ombud data
    axios.get(window.location.origin + '/db/sos-db-request.php?req_id=get_setting&name=ombudsperson_bild') //code to ./
    .then(response => {
      setOmbudImage(response.data[0][0]);
    });
    axios.get(window.location.origin + '/db/sos-db-request.php?req_id=get_setting&name=ombudsperson_text') //code to ./
    .then(response => {
      setOmbudText(response.data[0][0]);
    });
    axios.get(window.location.origin + '/db/sos-db-request.php?req_id=get_setting&name=ombudsperson_mail') //code to ./
    .then(response => {
      setOmbudMail(response.data[0][0]);
    });
    axios.get(window.location.origin + '/db/sos-db-request.php?req_id=get_setting&name=ombudsperson_phone') //code to ./
    .then(response => {
      setOmbudPhone(response.data[0][0]);
    });
  }, []);

  const navigateBack = () => {
    navigate(-1);
  }

  const navigateAnonymerKontakt = () => {
    navigate('../kontakt/anonymer-kontakt');
  }

  function handleDarkMode() {
    if (cookies.bgColor !== 'dark') {
      setCookie('bgColor', 'dark', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    } else {
      setCookie('bgColor', 'red', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    }
  }

  const callOmbud = () => {
    window.location.assign("tel:" + ombudPhone);
  }
  const mailtoOmbud = () => {
    window.location.assign("mailto:" + ombudMail + "?subject=Ich%20ben%C3%B6tige%20deine%20Unterst%C3%BCtzung%20%7C%20SOS-App&body=Hallo%20lieber%20Jonas%2C%0A%0Aich%20ben%C3%B6tige%20deine%20Unterst%C3%BCtzung%2C%20hier%20ist%20mein%20Anliegen%3A%0A%0A");
  }


  return (
    <div className={'SOS ' + cookies.bgColor}>
      <header className="SOS-header">
        <a href="/" title="Home"><img className="SOS-header-logo" src={logo} alt="LHG-Logo" /></a>
        <h1 className="SOS-header-title"><button className="SOS-back-button" onClick={navigateBack}>&lt;</button>Ombudsperson</h1>
        <SOSLinkButton addClass="SOS-header-privacy-button no-underline" title="🔅" click={handleDarkMode}/>
      </header>
      <div className="SOS-content">
        <SOSImg imgSrc={gelbesherz} url={ombudImage} />
        {parse(ombudText)}
        <br/>
        <h2>Du kannst dich gerne persönlich oder anonym an mich wenden: </h2>
        <div className="SOS-content-grid">
          <SOSImgButton title="Anrufen" imgSrc={telefon} click={callOmbud}/>
          <SOSImgButton title="E-Mail" imgSrc={email} click={mailtoOmbud}/>
          <SOSImgButton title="Anonymer Kontakt" imgSrc={schloss} click={navigateAnonymerKontakt}/>
        </div>

      </div>
      <footer className="SOS-footer">

      </footer>
    </div>
  );
}
