import SOSLinkButton from '../components/soslinkbutton';
import { useNavigate } from "react-router";
import logo from '../logo.png';
import { useCookies } from "react-cookie";

export default function App() {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['bgColor']);

  const navigateBack = () => {
    navigate(-1);
  }

  const navigateLogin = () => {
    navigate('../login');
  };

  function handleDarkMode() {
    if (cookies.bgColor !== 'dark') {
      setCookie('bgColor', 'dark', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    } else {
      setCookie('bgColor', 'red', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    }
  }

  return (
    <div className={'SOS ' + cookies.bgColor}>
      <header className="SOS-header">
        <a href="/" title="Home"><img className="SOS-header-logo" src={logo} alt="LHG-Logo" /></a>
        <h1 className="SOS-header-title"><button className="SOS-back-button" onClick={navigateBack}>&lt;</button>Impressum</h1>
        <SOSLinkButton addClass="SOS-header-privacy-button no-underline" title="🔅" click={handleDarkMode}/>
      </header>
      <div className="SOS-content">
      	<p>Kasse des LHG e.V.<br/>
          Reinhardtstraße 14<br/>
          10117 Berlin<br/>
          Tel:  +49 30 | 2838 9421<br/>
          Fax: +49 30 | 2838 9425<br/>
          E-Mail: kontakt@bundes-lhg.de
        </p>
        <p>Für weitere Informationen siehe das <a href="https://liberale-hochschulgruppen.de/impressum/" title="Impressum des Bundesverbands Liberaler Hochschulgruppen">Impressum des Bundesverbands Liberaler Hochschulgruppen.</a></p>
        <p>Datenschutzhinweis: Um korrekt zu funktionieren setzt diese Seite Cookies ein, mit der Verwendung erklärst du dich damit einverstanden. Keine Sorge, wir tracken dich nicht, sondern speichern lediglich notwendige Einstellungen wie bspw. das Setzen des Dark Modes :)</p>
        <p>Der bewusste Missbrauch dieser Plattform und insbesondere dort angebotener Nothilfedienste wie dem Notruf stellt ein satzungswidriges Verhalten und ggf. sogar eine Straftat dar. Wir behalten uns vor, solche Fälle zu verfolgen.</p>
        <SOSLinkButton title="Zum Login für Accountinhaber" addClass="center" click={navigateLogin}/>
      </div>
      <footer className="SOS-footer">
        <p>Made 2022 with ❤️ by Katharina Lauterbach and Maximilian Wiegand</p>
      </footer>
    </div>
  );
}
