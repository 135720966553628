import './components.css';
import { useState, useEffect, useRef } from "react";

export default function SOSCollapse({heading = '', content = '', addClass = ''}) {
  const [collapsed, setCollapsed] = useState();
  const reference = useRef();
  const [height, setHeight] = useState();

  useEffect(() => {
    setHeight(reference.current.clientHeight);
    setCollapsed(true);
  }, [])

  return (
      <div className={collapsed ? "SOS-collapse collapsed " + addClass : "SOS-collapse " + addClass}>
        <h2 className="SOS-collapse-heading" onClick={() => setCollapsed(!collapsed)}>{heading}</h2>
        <div className="SOS-collapse-content" ref={reference} style={collapsed ? {maxHeight: 0} : {maxHeight : height}}>
          {content}
        </div>
      </div>
  );
}
