import SOSImgButton from '../../components/sosimgbutton';
import SOSLinkButton from '../../components/soslinkbutton';
import { useNavigate } from "react-router";
import logo from '../../logo.png';
import { useCookies } from "react-cookie";
import anzug from '../../icons/anzug.png';

export default function App() {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['bgColor']);

  const navigateBack = () => {
    navigate('../assistenz?restore=true');
  }
  const navigateOrganisatoren = () => {
    navigate('/kontakt/organisatoren')
  }

  function handleDarkMode() {
    if (cookies.bgColor !== 'dark') {
      setCookie('bgColor', 'dark', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    } else {
      setCookie('bgColor', 'red', { path: '/', maxAge: 86400 }); //cookie expires after 24h
    }
  }

  return (
    <div className={'SOS ' + cookies.bgColor}>
      <header className="SOS-header">
        <a href="/" title="Home"><img className="SOS-header-logo" src={logo} alt="LHG-Logo" /></a>
        <h1 className="SOS-header-title"><button className="SOS-back-button" onClick={navigateBack}>&lt;</button>Assistenz</h1>
        <SOSLinkButton addClass="SOS-header-privacy-button no-underline" title="🔅" click={handleDarkMode}/>
      </header>
      <div className="SOS-content">
        <h1>Fragen zur Veranstaltung</h1>
        <p>Du hast dich verirrt oder benötigst etwas Orientierungshilfe auf der Veranstaltung? Dann kannst du dich jederzeit an eine der Organisatoren wenden:</p>
        <div className="SOS-content-grid">
          <SOSImgButton title="Organisatoren" imgSrc={anzug} click={navigateOrganisatoren}/>
        </div>
      </div>
      <footer className="SOS-footer">
      </footer>
    </div>
  );
}
