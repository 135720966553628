import { useCookies } from "react-cookie";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import axios from "axios";
import logo from '../logo.png';
import SOSInput from '../components/sosinput';
import SOSImg from '../components/sosimg';
import SOSButton from '../components/sosbutton';
import SOSLargeButton from '../components/soslargebutton';
import SOSLinkButton from '../components/soslinkbutton';
import SOSMessage from '../components/sosmessage';
import addphoto from '../icons/add-photo.png';

function App() {
  const navigate = useNavigate();
  const [loginCookie, setLoginCookie, removeLoginCookie] = useCookies(['login_token']);
  const [eventData, setEventData] = useState([]);
  const [trustPersons, setTrustPersons] = useState([]);
  const [orgaPersons, setOrgaPersons] = useState([]);

  const [nameFieldError, setNameFieldError] = useState(false);
  const [dateFieldError, setDateFieldError] = useState(false);
  const [trustPersonsErrorArray, setTrustPersonErrorArray] = useState([]);
  const [orgaPersonsErrorArray, setOrgaPersonErrorArray] = useState([]);
  const [disableButtons, setDisableButtons] = useState(false);
  const [imageLoadingArrayTP, setImageLoadingArrayTP] = useState([]);
  const [imageLoadingArrayOP, setImageLoadingArrayOP] = useState([]);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState('loading-square');

  const [msgEVError, setMsgEVError] = useState(false);
  const [msgTPError, setMsgTPError] = useState(false);
  const [msgOPError, setMsgOPError] = useState(false);
  const [msgNoTP, setMsgNoTP] = useState(false);
  const [msgNoOP, setMsgNoOP] = useState(false);
  const [msgInputError, setMsgInputError] = useState(false);
  const [msgUploadError, setMsgUploadError] = useState(false);
  const [msgNoDeleteTP, setMsgNoDeleteTP] = useState(false);
  const [msgNoDeleteOP, setMsgNoDeleteOP] = useState(false);
  const [msgDeleteError, setMsgDeleteError] = useState(false);

  //go to login page if not logged in, else get event data
  useEffect(() => {
    if (!loginCookie.login_token) {
      navigate('../login');
    } else {
      const params = new URLSearchParams(window.location.search);
      const event_id = params.get('event_id');

      //get event data
      axios.get(window.location.origin + '/db/sos-db-request.php?req_id=get_event&access_token=' + loginCookie.login_token + '&event_id=' + event_id) //code to ./
      .then(response => {
        setEventData(response.data[0]);
        setLoading('');

        //get trust persons
        if (response.data[0].length > 0 && response.data[0][3].length > 0) {
          axios.get(window.location.origin + '/db/sos-db-request.php?req_id=get_trust_persons&trust_persons=' + response.data[0][3]) //code to ./
          .then(response => {
            setTrustPersons(response.data);
            setLoading('');
          })
          .catch( (error) => {
            setLoading('');
          });
        }

        //get orga persons
        if (response.data[0].length > 0 && response.data[0][4].length > 0) {
          axios.get(window.location.origin + '/db/sos-db-request.php?req_id=get_orga_persons&orga_persons=' + response.data[0][4]) //code to ./
          .then(response => {
            setOrgaPersons(response.data);
            setLoading('');
          })
          .catch( (error) => {
            setLoading('');
          });
        }
      })
      .catch( (error) => {
        setLoading('');
        navigateOverview();
      });
    }
  }, []);

  const logout = () => {
    removeLoginCookie('login_token',{path:'/'});
    navigate('../login');
  }

  const navigateOverview = () => {
    navigate('../manage/overview');
  }

  const navigateBack = () => {
    navigate(-1);
  }

  const cancelForm = event => {
    event.preventDefault();
    navigateBack();
  }

  useEffect(() => {
    setTrustPersonErrorArray([]);
  }, [trustPersons]);
  useEffect(() => {
    setOrgaPersonErrorArray([]);
  }, [orgaPersons]);

  useEffect(() => {
    console.log(eventData);
    setNameFieldError(false);
    setDateFieldError(false);
  }, [eventData]);


  useEffect(() => {
    if (msgEVError) {
      setTimeout(() => {
        setMsgEVError(false);
      }, 5000);
    }
  }, [msgEVError]);
  useEffect(() => {
    if (msgTPError) {
      setTimeout(() => {
        setMsgTPError(false);
      }, 5000);
    }
  }, [msgTPError]);
  useEffect(() => {
    if (msgOPError) {
      setTimeout(() => {
        setMsgOPError(false);
      }, 5000);
    }
  }, [msgOPError]);
  useEffect(() => {
    if (msgNoTP) {
      setTimeout(() => {
        setMsgNoTP(false);
      }, 5000);
    }
  }, [msgNoTP]);
  useEffect(() => {
    if (msgNoOP) {
      setTimeout(() => {
        setMsgNoOP(false);
      }, 5000);
    }
  }, [msgNoOP]);
  useEffect(() => {
    if (msgInputError) {
      setTimeout(() => {
        setMsgInputError(false);
      }, 5000);
    }
  }, [msgInputError]);
  useEffect(() => {
    if (msgUploadError) {
      setTimeout(() => {
        setMsgUploadError(false);
      }, 5000);
    }
  }, [msgUploadError]);
  useEffect(() => {
    if (msgNoDeleteTP) {
      setTimeout(() => {
        setMsgNoDeleteTP(false);
      }, 5000);
    }
  }, [msgNoDeleteTP]);
  useEffect(() => {
    if (msgNoDeleteOP) {
      setTimeout(() => {
        setMsgNoDeleteOP(false);
      }, 5000);
    }
  }, [msgNoDeleteOP]);
  useEffect(() => {
    if (msgDeleteError) {
      setTimeout(() => {
        setMsgDeleteError(false);
      }, 5000);
    }
  }, [msgDeleteError]);


  const deleteEvent = event => {
    event.preventDefault();
    setDisableButtons(true);

    if (window.confirm('Möchtest du die Veranstaltung wirklich unwideruflich löschen?')) {
      const json = {
        req_id: 'delete_event',
        event_id: eventData[0],
        access_token: loginCookie.login_token,
      };

      const header = { 'Accept': 'application/json', 'Content-Type': 'application/json' };

      axios.post(window.location.origin + '/db/sos-db-request.php/', json, { header }) //code to ./
      .then(response => {
        setDisableButtons(false);
        navigateOverview();
      })
      .catch( (error) => {
        setMsgDeleteError(true);
        setDisableButtons(false);
      });
    }
  }

  const addTrustPerson = event => {
    event.preventDefault();
    setTrustPersons([...trustPersons, ['','','','']])
  }

  const deleteTrustPerson = (event, i) => {
    event.preventDefault();

    if (trustPersons.length > 1) {
      const arr = [...trustPersons];
      arr.splice(i,1)
      setTrustPersons(arr);
    } else {
      setMsgNoDeleteTP(true);
    }
  }

  const updateTrustPerson = (event, i, attr) => {
    const arr = [...trustPersons];
    arr[i][attr] = event.target.value;
    setTrustPersons(arr);
  }

  const addOrgaPerson = event => {
    event.preventDefault();
    setOrgaPersons([...orgaPersons, ['','','','']])
  }

  const deleteOrgaPerson = (event, i) => {
    event.preventDefault();

    if (orgaPersons.length > 1) {
      const arr = [...orgaPersons];
      arr.splice(i,1)
      setOrgaPersons(arr);
    } else {
      setMsgNoDeleteOP(true);
    }
  }

  const updateOrgaPerson = (event, i, attr) => {
    const arr = [...orgaPersons];
    arr[i][attr] = event.target.value;
    setOrgaPersons(arr);
  }

  const updateEventData = (event, attr) => {
    const arr = [...eventData];
    arr[attr] = event.target.value;
    setEventData(arr);
  }

  const uploadTPImage = (event, i) => {
    event.preventDefault();
    setDisableButtons(true);
    setImageLoadingArrayTP([...imageLoadingArrayTP, i]);

    var file = event.target.files[0];
    const formData = new FormData();
    formData.append('access_token', loginCookie.login_token);
    formData.append('File', file);

    axios.post(window.location.origin + '/db/sos-upload-img.php', formData) //code to ./
    .then(response => {
      const arr = [...trustPersons];
      arr[i][3] = response.data;
      setTrustPersons(arr);

      setImageLoadingArrayTP(imageLoadingArrayTP.splice(i,1));
      setDisableButtons(false);
    })
    .catch( (error) => {
      setMsgUploadError(true);
      setImageLoadingArrayTP(imageLoadingArrayTP.splice(i,1));
      setDisableButtons(false);
    });
  }

  const uploadOPImage = (event, i) => {
    event.preventDefault();
    setDisableButtons(true);
    setImageLoadingArrayOP([...imageLoadingArrayOP, i]);

    var file = event.target.files[0];
    const formData = new FormData();
    formData.append('access_token', loginCookie.login_token);
    formData.append('File', file);

    axios.post(window.location.origin + '/db/sos-upload-img.php', formData) //code to ./
    .then(response => {
      const arr = [...orgaPersons];
      arr[i][3] = response.data;
      setOrgaPersons(arr);

      setImageLoadingArrayOP(imageLoadingArrayOP.splice(i,1));
      setDisableButtons(false);
    })
    .catch( (error) => {
      setMsgUploadError(true);
      setImageLoadingArrayOP(imageLoadingArrayOP.splice(i,1));
      setDisableButtons(false);
    });
  }

  const deleteTPImage = (event, i) => {
    event.preventDefault();

    const arr = [...trustPersons];
    arr[i][3] = '';
    setTrustPersons(arr);
  }

  const deleteOPImage = (event, i) => {
    event.preventDefault();

    const arr = [...orgaPersons];
    arr[i][3] = '';
    setOrgaPersons(arr);
  }

  //check if data is valid and start sending
  const checkAndSendData = event => {
    event.preventDefault();
    setDisableButtons(true);

    //send trust persons if everything is okay, orga & event data will be send afterwards if everything's okay
    if (checkEventData()) {
      sendUpdatedTPData();
    } else {
      setDisableButtons(false);
    }
  }

  const checkEventData = () => {
    //check name field
    if (eventData[1].length < 1) {
      setNameFieldError(true);
      setMsgInputError(true);
      return false;
    }

    //check date field
    if (eventData[2].length < 2 || Date.parse(eventData[2]) == NaN || eventData[2] == '0000-00-00' || Date.parse(eventData[2]) < new Date().setHours(0,0,0,0)) {
      setDateFieldError(true);
      setMsgInputError(true);
      return false;
    }

    //check trustPersons

    if (trustPersons.length == 0) {
      setMsgNoTP(true);
      return false;
    } else {
      var tpError = false;

      trustPersons.forEach((item, i) => {
        if (item[1].length < 1 || item[2].length < 1 || !item[2].match(/\+?([0123456789\s]{6,18})/)) {
          setTrustPersonErrorArray([...trustPersonsErrorArray, i]);
          tpError = true;
        }
      });
      if (tpError) {
        setMsgInputError(true);
        return false;
      }
    }

    //check orgaPersons

    if (orgaPersons.length == 0) {
      setMsgNoOP(true);
      return false;
    } else {
      var opError = false;

      orgaPersons.forEach((item, i) => {
        if (item[1].length < 1 || item[2].length < 1 || !item[2].match(/\+?([0123456789\s]{6,18})/)) {
          setOrgaPersonErrorArray([...orgaPersonsErrorArray, i]);
          opError = true;
        }
      });
      if (opError) {
        setMsgInputError(true);
        return false;
      }
    }

    return true;
  }

  const sendUpdatedTPData = () => {
    //first update trust persons
    if (trustPersons.length > 0) {
      trustPersons.forEach((item, i) => {
        const json = {
          req_id: 'update_person',
          id: item[0],
          name: item[1],
          telefon: item[2],
          image_path: item[3],
          access_token: loginCookie.login_token,
        };

        const header = { 'Accept': 'application/json', 'Content-Type': 'application/json' };

        axios.post(window.location.origin + '/db/sos-db-request.php/', json, { header }) //code to ./
        .then(response => {
          //add gotten ID if new person
          if (item[0] == '') {
            const arr = [...trustPersons];
            arr[i][0] = response.data.toString();
            setTrustPersons(arr);
          }

          //if everything done successfully,
          if (i == trustPersons.length - 1) {
            sendUpdatedOPData();
          }
        })
        .catch( (error) => {
          setMsgTPError(true);
          setDisableButtons(false);
        });
      });
    }
  }

  const sendUpdatedOPData = () => {
    //first update orga persons
    if (orgaPersons.length > 0) {
      orgaPersons.forEach((item, i) => {
        const json = {
          req_id: 'update_person',
          id: item[0],
          name: item[1],
          telefon: item[2],
          image_path: item[3],
          access_token: loginCookie.login_token,
        };

        const header = { 'Accept': 'application/json', 'Content-Type': 'application/json' };

        axios.post(window.location.origin + '/db/sos-db-request.php/', json, { header }) //code to ./
        .then(response => {
          //add gotten ID if new person
          if (item[0] == '') {
            const arr = [...orgaPersons];
            arr[i][0] = response.data.toString();
            setOrgaPersons(arr);
          }

          //if everything done successfully,
          if (i == orgaPersons.length - 1) {
            sendUpdatedEventData();
          }
        })
        .catch( (error) => {
          setMsgOPError(true);
          setDisableButtons(false);
        });
      });
    }
  }

  const sendUpdatedEventData = () => {
    var tp = '';
    trustPersons.forEach((item, i) => {
      tp += i < trustPersons.length - 1 ? item[0] + ',' : item[0];
    });

    var op = '';
    orgaPersons.forEach((item, i) => {
      op += i < orgaPersons.length - 1 ? item[0] + ',' : item[0];
    });

    //send updated event data
    if (eventData.length > 0) {
      const json = {
        req_id: 'update_event',
        id: eventData[0],
        name: eventData[1],
        date: eventData[2],
        trust_persons: tp,
        orga_persons: op,
        access_token: loginCookie.login_token,
      };

      const header = { 'Accept': 'application/json', 'Content-Type': 'application/json' };

      axios.post(window.location.origin + '/db/sos-db-request.php/', json, { header }) //code to ./
      .then(response => {
        setSuccess(true);
        setTimeout(function () {
          setDisableButtons(false);
          navigateOverview();
        }, 5000);
      })
      .catch( (error) => {
        setMsgEVError(true);
        setDisableButtons(false);
      });
    }
  }

  return (
    <div className="SOS grey">
      <header className="SOS-header">
        <img className="SOS-header-logo" src={logo} alt="LHG-Logo"/>
        <h1 className="SOS-header-title">Bearbeiten</h1>
        <SOSLinkButton addClass="SOS-header-logout-button" title="Logout" click={logout}/>
      </header>
      <form className="SOS-content">
        <div className={loading}>
          <label>Name<SOSInput title="Name" val={eventData[1]} onChg={(event) => updateEventData(event, 1)} addClass={nameFieldError ? 'error' : ''}/></label>
          <label>Datum (bei mehrtägigen Events: letzter Tag)<SOSInput title="Datum" type="date" val={eventData[2]} onChg={(event) => updateEventData(event, 2)} addClass={dateFieldError ? 'error' : ''}/></label>

          <h2>Organisatoren</h2>
          <div className="SOS-backend-person-list">
            {orgaPersons.length > 0 && orgaPersons.map((person, i) =>
              <div className={orgaPersonsErrorArray.indexOf(i) != -1 ? 'SOS-backend-person error' : 'SOS-backend-person'} key={i}>
                <div>
                  <SOSLinkButton type="button" title="❌" addClass="red-button center no-underline" click={(event) => deleteOrgaPerson(event, i)}/>
                </div>
                <div>
                  <label className="SOS-file-upload">
                    <SOSImg imgSrc={addphoto} loading={imageLoadingArrayOP.indexOf(i) != -1 ? 'loading' : ''} url={orgaPersons[i][3] !== '' ? window.location.origin + '/' + orgaPersons[i][3] : ''}/>
                    <input type="file" name="file" style={{display: 'none'}} onChange={(event) => uploadOPImage(event, i)} accept="image/png, image/jpeg"/>
                  </label>
                  {orgaPersons[i][3] !== '' ? <SOSLinkButton type="button" title="Bild löschen" addClass="center" click={(event) => deleteOPImage(event, i)}/> : ''}
                </div>
                <div>
                  <label>Name<SOSInput title="Name" val={orgaPersons[i][1]} onChg={(event) => updateOrgaPerson(event, i, 1)}/></label>
                  <label>Telefonnummer<SOSInput title="Telefonnummer" val={orgaPersons[i][2]} onChg={(event) => updateOrgaPerson(event, i, 2)}/></label>
                </div>
              </div>
            )}
            {orgaPersons.length == 0 ? <p className="center">Noch keine Organisatoren vorhanden. Bitte füge Organisatoren hinzu.</p> : ''}
            <SOSLinkButton addClass="center" title="Person hinzufügen" click={(event) => addOrgaPerson(event)}/>
          </div>

          <h2>Vertrauenspersonen</h2>
          <div className="SOS-backend-person-list">
            {trustPersons.length > 0 && trustPersons.map((person, i) =>
              <div className={trustPersonsErrorArray.indexOf(i) != -1 ? 'SOS-backend-person error' : 'SOS-backend-person'} key={i}>
                <div>
                  <SOSLinkButton type="button" title="❌" addClass="red-button center no-underline" click={(event) => deleteTrustPerson(event, i)}/>
                </div>
                <div>
                  <label className="SOS-file-upload">
                    <SOSImg imgSrc={addphoto} loading={imageLoadingArrayTP.indexOf(i) != -1 ? 'loading' : ''} url={trustPersons[i][3] !== '' ? window.location.origin + '/' + trustPersons[i][3] : ''}/>
                    <input type="file" name="file" style={{display: 'none'}} onChange={(event) => uploadTPImage(event, i)} accept="image/png, image/jpeg"/>
                  </label>
                  {trustPersons[i][3] !== '' ? <SOSLinkButton type="button" title="Bild löschen" addClass="center" click={(event) => deleteTPImage(event, i)}/> : ''}
                </div>
                <div>
                  <label>Name<SOSInput title="Name" val={trustPersons[i][1]} onChg={(event) => updateTrustPerson(event, i, 1)}/></label>
                  <label>Telefonnummer<SOSInput title="Telefonnummer" val={trustPersons[i][2]} onChg={(event) => updateTrustPerson(event, i, 2)}/></label>
                </div>
              </div>
            )}
            {trustPersons.length == 0 ? <p className="center">Noch keine Vertrauenspersonen vorhanden. Bitte füge Vertrauenspersonen hinzu.</p> : ''}
            <SOSLinkButton addClass="center" title="Person hinzufügen" click={(event) => addTrustPerson(event)}/>
          </div>
          <SOSButton title="Änderungen speichern" click={(event) => checkAndSendData(event)} disabled={disableButtons}/>
          <SOSButton title="Abbrechen" type="button" click={(event) => cancelForm(event)} disabled={disableButtons}/>
          <SOSButton title="Löschen" addClass="red-button" click={(event) => deleteEvent(event)} disabled={disableButtons}/>
        </div>
      </form>
      <SOSMessage title="Fehler" message="Die Veranstaltung konnte nicht gespeichert werden. Bitte versuche es später erneut.\nFehlercode: Error EVError" show={msgEVError} addClass="red" click={() => setMsgEVError(false)}/>
      <SOSMessage title="Fehler" message="Die Veranstaltung konnte nicht gespeichert werden. Bitte versuche es später erneut.\nFehlercode: Error TPError" show={msgTPError} addClass="red" click={() => setMsgTPError(false)}/>
      <SOSMessage title="Fehler" message="Die Veranstaltung konnte nicht gespeichert werden. Bitte versuche es später erneut.\nFehlercode: Error OPError" show={msgOPError} addClass="red" click={() => setMsgOPError(false)}/>
      <SOSMessage title="Fehler" message="Bitte füge eine Vertrauenspersonen hinzu." show={msgNoTP} addClass="red" click={() => setMsgNoTP(false)}/>
      <SOSMessage title="Fehler" message="Bitte füge eine Orga-Person hinzu." show={msgNoOP} addClass="red" click={() => setMsgNoOP(false)}/>
      <SOSMessage title="Fehler" message="Bitte überprüfe deine Eingaben." show={msgInputError} addClass="red" click={() => setMsgInputError(false)}/>
      <SOSMessage title="Fehler" message="Ein Fehler beim Upload ist aufgetreten, bitte versuche es erneut." show={msgUploadError} addClass="red" click={() => setMsgUploadError(false)}/>
      <SOSMessage title="Fehler" message="Mindestens eine Vertrauensperson muss vorhanden sein!" show={msgNoDeleteTP} addClass="red" click={() => setMsgNoDeleteTP(false)}/>
      <SOSMessage title="Fehler" message="Mindestens eine Orga-Person muss vorhanden sein!" show={msgNoDeleteOP} addClass="red" click={() => setMsgNoDeleteOP(false)}/>
      <SOSMessage title="Fehler" message="Die Veranstaltung konnte nicht gelöscht werden. Bitte versuche es später erneut." show={msgDeleteError} addClass="red" click={() => setMsgDeleteError(false)}/>
      <SOSMessage title="Erfolg" message="Die Veranstaltung wurde erfolgreich gespeichert." show={success} addClass="green" click={() => setSuccess(false)}/>
      <footer className="SOS-footer">
      </footer>
    </div>
  );
}

export default App;
