import './components.css';
import React, { useState, useEffect, useRef } from 'react'

export default function SOSTimer({sec = 10, action}) {
  const [remainSec, setRemainSec] = useState(sec);
  var remS = sec;
  const inputRef = useRef();

  useEffect(() => {
    const interval = setInterval(() => {
      if (remS > 1) {
        remS--;
        setRemainSec(remainSec => remainSec-1);
      } else {
        remS--;
        setRemainSec(remainSec => 0);
        inputRef.current.click();
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="SOS-timer">
      <span className="SOS-timer-count">{remainSec}</span>
      <p>{remainSec == 1 ? 'SEKUNDE' : 'SEKUNDEN'}</p>
      <input ref={inputRef} style={{display: 'none'}} onClick={action}/>
    </div>
  );
}
