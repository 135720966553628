import './components.css';
import { useState, useEffect } from "react";

export default function SOSMessage({addClass ='', title, message, show, click}) {
  const [display, setDisplay] = useState('hidden');

  useEffect(() => {
    setTimeout(() => {setDisplay('visible')}, 1000);
  }, [])

  return (
    <div className={show ? "SOS-message-box show " + addClass : "SOS-message-box " + addClass} style={{visibility: display}}>
      <div className="SOS-message-box-content">
        <h3>{title}</h3>
        <p>{message}</p>
      </div>
      <div className="SOS-message-box-close"><p onClick={click}>X</p></div>
    </div>
  );
}
